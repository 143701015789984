<template>
  <div class="brace bg-white lg:flex lg:pt-10 lg:bg-sable container-prevention">
    <div class="flex-none">
      <section-category-sidebar
        :title="t('prevention')"
        :items="items"
        header-class="bg-orange"
        category-hover-class="hover:bg-orange-300 hover:font-bold hover:text-white"
        post-hover-class="hover:bg-orange-900 hover:font-bold"
        category-active-class="bg-orange-300 _font-bold hover:bg-orange text-white"
        post-active-class="bg-orange-900 _font-bold"
        mobile-category-open-class="bg-orange"
        mobile-category-closed-class="bg-orange-900"
        mobile-post-active-class="bg-orange-300 _font-bold text-white"
        border-color="#8E2801"
      ></section-category-sidebar>
    </div>

    <div class="flex-1">
      <nuxt-page></nuxt-page>
    </div>
  </div>
</template>

<script>
import SectionCategorySidebar from "~/components/Sections/SectionCategorySidebar.vue";


import { setupMeta } from "~/utils/utils";

export default {
  name: "PagePreventionCategory",
  async setup() {
    const { t, locale } = useI18n({
      useScope: "local",
    });
    const nuxtApp = useNuxtApp();
    const { items, seo } = nuxtApp.$store.state.web.prevention;

    await nuxtApp.runWithContext(() => setupMeta(null, seo, locale));

    return { t, items };
  },
  components: {
    SectionCategorySidebar,
  },
};
</script>

<i18n lang="json">
{
  "fr": {
    "prevention": "Je préviens"
  },
  "en": {
    "prevention": "Prevention"
  }
}
</i18n>

<style lang="scss" scoped>
.text-1\.375rem {
  font-size: 1.375rem;
}

.lg\:max-w-342px {
  @media (min-width: 1024px) {
    max-width: 342px;
  }
}
</style>
